import Vue from 'vue'
import Router from 'vue-router'
// import stldemo from "@/components/StlDemo"
// import objdemo from "@/components/ObjDemo"
// import MtlDemo from "@/components/MtlDemo"

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
        return originalPush.call(this, location).catch(err => err)
    }
    // 安装路由
Vue.use(Router)



export default new Router({
    mode:'history',
    routes: [{
            path: '/',
            name: 'User',
            component: resolve => (require(["@/components/User"], resolve)),
            meta: {
                title: '壹盒动漫'
            }
            // children: [{
            //     path: 'stl',
            //     name: 'stldemo',
            //     // component: stldemo
            //     component: resolve => (require(["@/components/StlDemo"], resolve))
            // }
            // , {
            //     path: 'obj',
            //     name: 'objdemo',
            //     component: resolve => (require(["@/components/ObjDemo"], resolve))
            // }, {
            //     path: 'mtl',
            //     name: 'mtlDemo',
            //     // component: MtlDemo
            //     component: resolve => (require(["@/components/MtlDemo"], resolve))
            // }, ]
        },
        {
            path: '/app',
            name: 'app',
            // component: MtlDemo
            component: resolve => (require(["@/components/App"], resolve)),
            meta: {
                title: 'App'
            }
        },
    ]
})