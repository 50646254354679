<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'App',
         data() {
        return{
handlechufa:""
        }       
    },
created() {
        //   this.toLink('/');   
},
        methods:{
            toLink(val){
                this.$router.push(val);
            },
         
        }
    }
</script>

<style>
    html,body{ 
      margin: 0;
    padding: 0;

} 
   
</style>
