import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'
import "lib-flexible/flexible";
import axios from 'axios'
import { MaxEquation } from 'three';
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(Element)
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
new Vue({
    router,
    App,
    render: h => h(App)
}).$mount("#app")